<template>
  <sign-page
    title-text="薪级等级"
    :request="request"
    table-size="large"
    :column-list="columnList"
    :form-parms-add="formParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  wageLevelRequest as request
} from '@/api'

export default {
  computed: {
    formParms: {
      get () {
        let data = [{
          type: 'select',
          label: '薪级所属岗位',
          selectDatas: this.$store.getters.selectDatas['job_title_type'],
          key: 'jobTitleType',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '薪级名称',
          key: 'name'
        },
        {
          type: 'input',
          label: '薪级等级',
          key: 'level',
          check: {
            rules: {
              type: 'integer'
            }
          }
        },
        {
          type: 'input',
          label: '工资标准',
          key: 'wage',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    columnList: {
      get () {
        let data = this.formParms.map(v => {
          return {
            title: v.label,
            sort: true,
            field: v.key
          }
        })
        return data
      }
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
